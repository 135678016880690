/* BOOTSTRAP FROZEN 4.2.1 */ // TODO PODER PONER EN UN UNICO SCSS
import '../scss/bootstrap.scss';

/* UNIFY TEMPLATE 2.6.2 */
import '../scss/unify/unify.scss';

/* CUSTOM CSS */
import '../scss/front/custom.scss';

// Usamos IMPORT porque es la sintaxis oficial para importar modulos de ECMAScript
// Require es nomenclatura de NodeJS
import $ from 'jquery';
// Importamos JQuery de forma global. Para Scripts añadidos directamente en la plantilla.
// global.$ = $;

import 'popper.js';
import 'bootstrap';

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});
